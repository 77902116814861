import React from 'react';

const IconLoader = () => (
  <svg id="logo" width="600" height="500" viewBox="0 0 600 500" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Loader Logo</title>
        <mask
          id="path-1-outside-1"
          maskUnits="userSpaceOnUse"
          x="124.76"
          y="216.88"
          width="371"
          height="78"
          fill="black">
          <rect fill="white" x="124.76" y="216.88" width="371" height="78" />
          <path d="M168.56 243.496C168.56 249.064 166.928 253.576 163.664 257.032C160.4 260.424 156.016 262.12 150.512 262.12C148.464 262.12 146.672 261.8 145.136 261.16C143.6 260.456 142.832 259.304 142.832 257.704C147.696 257.704 151.28 256.584 153.584 254.344C155.888 252.104 157.04 248.616 157.04 243.88C157.04 239.848 156.048 236.872 154.064 234.952C152.144 232.968 149.168 231.976 145.136 231.976C143.152 231.976 141.104 232.136 138.992 232.456V289H127.76V226.024C135.312 225.512 141.328 225.256 145.808 225.256C153.104 225.256 158.704 226.696 162.608 229.576C166.576 232.392 168.56 237.032 168.56 243.496Z" />
          <path d="M201.883 284.584C200.667 286.824 198.875 288.456 196.507 289.48C194.203 290.568 191.579 291.112 188.635 291.112C184.219 291.112 180.507 289.768 177.499 287.08C174.555 284.392 173.083 280.904 173.083 276.616C173.083 266.248 182.331 261.064 200.827 261.064V252.712C200.827 249.512 200.347 247.144 199.387 245.608C198.427 244.008 196.603 243.208 193.915 243.208C191.675 243.208 189.819 243.816 188.347 245.032C186.875 246.184 186.139 247.816 186.139 249.928C186.139 251.272 186.395 252.712 186.907 254.248C186.011 254.952 184.731 255.304 183.067 255.304C180.763 255.304 178.939 254.728 177.595 253.576C176.251 252.424 175.579 250.76 175.579 248.584C175.579 245.512 177.307 243.016 180.763 241.096C184.219 239.112 188.827 238.12 194.587 238.12C198.811 238.12 202.139 238.76 204.571 240.04C207.003 241.256 208.731 243.176 209.755 245.8C210.843 248.36 211.387 251.816 211.387 256.168V281.128C211.387 284.264 212.379 286.376 214.363 287.464C213.851 288.424 213.083 289.16 212.059 289.672C211.099 290.184 209.979 290.44 208.699 290.44C206.843 290.44 205.307 289.928 204.091 288.904C202.875 287.816 202.139 286.376 201.883 284.584ZM192.379 284.68C195.195 284.68 197.307 283.624 198.715 281.512C200.123 279.336 200.827 276.648 200.827 273.448V265.864C190.011 265.864 184.603 269.32 184.603 276.232C184.603 278.792 185.275 280.84 186.619 282.376C188.027 283.912 189.947 284.68 192.379 284.68Z" />
          <path d="M240.955 245.512C238.203 245.512 236.411 247.368 235.579 251.08C234.811 254.728 234.427 259.112 234.427 264.232V289H223.867V240.04L232.699 238.888L233.179 247.528C234.075 244.648 235.643 242.376 237.883 240.712C240.123 238.984 242.715 238.12 245.659 238.12C248.859 238.12 251.387 239.048 253.243 240.904C255.163 242.76 256.123 245.288 256.123 248.488C256.123 253.672 253.627 256.264 248.635 256.264C246.971 256.264 245.691 255.912 244.795 255.208C245.243 253.544 245.467 252.104 245.467 250.888C245.467 247.304 243.963 245.512 240.955 245.512Z" />
          <path d="M279.214 243.784C272.814 243.784 269.614 250.76 269.614 264.712C269.614 270.408 270.318 275.016 271.726 278.536C273.198 281.992 276.014 283.72 280.174 283.72C282.99 283.72 285.39 282.984 287.374 281.512C289.422 279.976 290.798 277.832 291.502 275.08C292.59 275.592 293.358 276.2 293.806 276.904C294.318 277.544 294.574 278.44 294.574 279.592C294.574 281.896 293.806 283.944 292.27 285.736C290.798 287.464 288.878 288.776 286.51 289.672C284.142 290.632 281.71 291.112 279.214 291.112C272.174 291.112 266.958 288.84 263.566 284.296C260.174 279.688 258.478 273.512 258.478 265.768C258.478 257.512 260.302 250.856 263.95 245.8C267.662 240.68 273.23 238.12 280.654 238.12C285.006 238.12 288.59 239.016 291.406 240.808C294.222 242.6 295.63 245.352 295.63 249.064C295.63 251.24 294.958 252.904 293.614 254.056C292.27 255.208 290.446 255.784 288.142 255.784C287.438 255.784 286.702 255.688 285.934 255.496C285.23 255.304 284.654 255.048 284.206 254.728C284.718 253.192 284.974 251.752 284.974 250.408C284.974 245.992 283.054 243.784 279.214 243.784Z" />
          <path d="M315.53 231.496C315.53 238.472 315.21 243.592 314.57 246.856C315.914 243.976 317.802 241.8 320.234 240.328C322.666 238.856 325.514 238.12 328.778 238.12C334.154 238.12 337.898 239.624 340.01 242.632C342.122 245.576 343.178 250.088 343.178 256.168V281.128C343.178 284.264 344.17 286.376 346.154 287.464C345.642 288.424 344.81 289.16 343.658 289.672C342.506 290.184 341.29 290.44 340.01 290.44C337.514 290.44 335.658 289.736 334.442 288.328C333.226 286.92 332.618 284.936 332.618 282.376V257.512C332.618 253.48 332.17 250.472 331.274 248.488C330.378 246.504 328.394 245.512 325.322 245.512C321.418 245.512 318.794 247.24 317.45 250.696C316.17 254.152 315.53 258.6 315.53 264.04V289H304.97V221.8L315.53 220.648V231.496Z" />
          <path d="M361.056 219.88C363.168 219.88 364.864 220.392 366.144 221.416C367.488 222.44 368.16 223.912 368.16 225.832C368.16 227.816 367.456 229.352 366.048 230.44C364.704 231.464 362.944 231.976 360.768 231.976C358.656 231.976 356.96 231.464 355.68 230.44C354.4 229.416 353.76 227.944 353.76 226.024C353.76 224.04 354.432 222.536 355.776 221.512C357.184 220.424 358.944 219.88 361.056 219.88ZM366.048 281.128C366.048 284.264 367.04 286.376 369.024 287.464C368.512 288.424 367.68 289.16 366.528 289.672C365.376 290.184 364.128 290.44 362.784 290.44C360.352 290.44 358.528 289.736 357.312 288.328C356.096 286.92 355.488 284.936 355.488 282.376V240.04H366.048V281.128Z" />
          <path d="M410.029 275.464C411.501 276.168 412.237 277.544 412.237 279.592C412.237 281.896 411.469 283.944 409.933 285.736C408.397 287.464 406.413 288.776 403.981 289.672C401.549 290.632 399.085 291.112 396.589 291.112C389.549 291.112 384.333 288.84 380.941 284.296C377.549 279.688 375.853 273.512 375.853 265.768C375.853 257.512 377.677 250.856 381.325 245.8C385.037 240.68 390.637 238.12 398.125 238.12C402.861 238.12 406.669 239.368 409.549 241.864C412.429 244.296 413.869 247.752 413.869 252.232C413.869 257.8 411.917 261.704 408.013 263.944C404.173 266.184 398.925 267.304 392.269 267.304C390.413 267.304 388.653 267.24 386.989 267.112C387.181 272.168 388.013 276.2 389.485 279.208C391.021 282.216 393.709 283.72 397.549 283.72C400.365 283.72 402.829 283.016 404.941 281.608C407.117 280.136 408.813 278.088 410.029 275.464ZM396.589 243.592C393.133 243.592 390.701 245.416 389.293 249.064C387.885 252.648 387.117 257.064 386.989 262.312C388.909 262.44 390.317 262.504 391.213 262.504C399.277 262.504 403.309 259.272 403.309 252.808C403.309 250.184 402.733 248.008 401.581 246.28C400.493 244.488 398.829 243.592 396.589 243.592Z" />
          <path d="M439.646 263.896C439.646 265.592 439.086 267.112 437.966 268.456C436.846 269.768 435.39 270.904 433.598 271.864C435.806 273.144 437.582 274.52 438.926 275.992C440.27 277.464 440.942 279.192 440.942 281.176C440.942 284.184 439.918 286.424 437.87 287.896C435.822 289.336 433.102 290.056 429.71 290.056C426.414 290.056 423.726 289.32 421.646 287.848C419.566 286.344 418.526 284.136 418.526 281.224C418.526 279.4 419.15 277.72 420.398 276.184C421.678 274.648 423.278 273.432 425.198 272.536C423.438 271.256 422.062 269.992 421.07 268.744C420.078 267.464 419.582 266.008 419.582 264.376C419.582 261.784 420.542 259.8 422.462 258.424C424.382 257.048 426.814 256.36 429.758 256.36C432.67 256.36 435.038 256.984 436.862 258.232C438.718 259.48 439.646 261.368 439.646 263.896ZM425.87 263.896C425.87 265.08 426.302 266.152 427.166 267.112C428.03 268.04 429.262 269.032 430.862 270.088C431.822 269.352 432.558 268.456 433.07 267.4C433.614 266.312 433.886 265.144 433.886 263.896C433.886 262.648 433.502 261.64 432.734 260.872C431.966 260.104 430.974 259.72 429.758 259.72C428.542 259.72 427.582 260.104 426.878 260.872C426.206 261.608 425.87 262.616 425.87 263.896ZM434.654 281.704C434.654 280.744 434.35 279.848 433.742 279.016C433.134 278.184 432.398 277.448 431.534 276.808C430.67 276.168 429.438 275.352 427.838 274.36C426.846 275.224 426.062 276.264 425.486 277.48C424.91 278.696 424.622 279.944 424.622 281.224C424.622 282.92 425.054 284.264 425.918 285.256C426.814 286.216 428.078 286.696 429.71 286.696C431.31 286.696 432.526 286.264 433.358 285.4C434.222 284.536 434.654 283.304 434.654 281.704Z" />
          <path d="M467.439 257.32L467.775 260.824L454.527 290.056L448.191 289L462.255 261.832L444.447 261.88L444.783 257.32H467.439Z" />
          <path d="M484.048 256.36C485.776 256.36 487.311 256.728 488.655 257.464C490.031 258.2 490.72 259.256 490.72 260.632C490.72 261.08 490.608 261.464 490.384 261.784C490.192 262.104 489.887 262.408 489.471 262.696C488.191 261.384 486.511 260.728 484.431 260.728C481.807 260.728 479.839 261.704 478.527 263.656C477.247 265.576 476.543 268.12 476.415 271.288C476.927 270.072 477.839 269.128 479.151 268.456C480.495 267.784 481.968 267.448 483.568 267.448C486.416 267.448 488.607 268.472 490.143 270.52C491.679 272.536 492.448 275.096 492.448 278.2C492.448 280.44 492 282.472 491.104 284.296C490.24 286.088 488.991 287.496 487.359 288.52C485.727 289.544 483.823 290.056 481.647 290.056C474.191 290.056 470.464 284.792 470.464 274.264C470.464 271.128 470.976 268.2 472 265.48C473.024 262.728 474.56 260.52 476.608 258.856C478.656 257.192 481.136 256.36 484.048 256.36ZM481.647 271C480.079 271 478.911 271.72 478.143 273.16C477.407 274.568 476.976 276.36 476.848 278.536C476.944 280.776 477.359 282.696 478.095 284.296C478.831 285.896 480.015 286.696 481.647 286.696C483.311 286.696 484.511 285.88 485.247 284.248C486.015 282.616 486.4 280.712 486.4 278.536C486.4 276.616 485.999 274.888 485.199 273.352C484.399 271.784 483.215 271 481.647 271Z" />
        </mask>
        <path
          d="M168.56 243.496C168.56 249.064 166.928 253.576 163.664 257.032C160.4 260.424 156.016 262.12 150.512 262.12C148.464 262.12 146.672 261.8 145.136 261.16C143.6 260.456 142.832 259.304 142.832 257.704C147.696 257.704 151.28 256.584 153.584 254.344C155.888 252.104 157.04 248.616 157.04 243.88C157.04 239.848 156.048 236.872 154.064 234.952C152.144 232.968 149.168 231.976 145.136 231.976C143.152 231.976 141.104 232.136 138.992 232.456V289H127.76V226.024C135.312 225.512 141.328 225.256 145.808 225.256C153.104 225.256 158.704 226.696 162.608 229.576C166.576 232.392 168.56 237.032 168.56 243.496Z"
          stroke="currentColor"
          stroke-width="6"
          mask="url(#path-1-outside-1)"
        />
        <path
          d="M201.883 284.584C200.667 286.824 198.875 288.456 196.507 289.48C194.203 290.568 191.579 291.112 188.635 291.112C184.219 291.112 180.507 289.768 177.499 287.08C174.555 284.392 173.083 280.904 173.083 276.616C173.083 266.248 182.331 261.064 200.827 261.064V252.712C200.827 249.512 200.347 247.144 199.387 245.608C198.427 244.008 196.603 243.208 193.915 243.208C191.675 243.208 189.819 243.816 188.347 245.032C186.875 246.184 186.139 247.816 186.139 249.928C186.139 251.272 186.395 252.712 186.907 254.248C186.011 254.952 184.731 255.304 183.067 255.304C180.763 255.304 178.939 254.728 177.595 253.576C176.251 252.424 175.579 250.76 175.579 248.584C175.579 245.512 177.307 243.016 180.763 241.096C184.219 239.112 188.827 238.12 194.587 238.12C198.811 238.12 202.139 238.76 204.571 240.04C207.003 241.256 208.731 243.176 209.755 245.8C210.843 248.36 211.387 251.816 211.387 256.168V281.128C211.387 284.264 212.379 286.376 214.363 287.464C213.851 288.424 213.083 289.16 212.059 289.672C211.099 290.184 209.979 290.44 208.699 290.44C206.843 290.44 205.307 289.928 204.091 288.904C202.875 287.816 202.139 286.376 201.883 284.584ZM192.379 284.68C195.195 284.68 197.307 283.624 198.715 281.512C200.123 279.336 200.827 276.648 200.827 273.448V265.864C190.011 265.864 184.603 269.32 184.603 276.232C184.603 278.792 185.275 280.84 186.619 282.376C188.027 283.912 189.947 284.68 192.379 284.68Z"
          stroke="currentColor"
          stroke-width="6"
          mask="url(#path-1-outside-1)"
        />
        <path
          d="M240.955 245.512C238.203 245.512 236.411 247.368 235.579 251.08C234.811 254.728 234.427 259.112 234.427 264.232V289H223.867V240.04L232.699 238.888L233.179 247.528C234.075 244.648 235.643 242.376 237.883 240.712C240.123 238.984 242.715 238.12 245.659 238.12C248.859 238.12 251.387 239.048 253.243 240.904C255.163 242.76 256.123 245.288 256.123 248.488C256.123 253.672 253.627 256.264 248.635 256.264C246.971 256.264 245.691 255.912 244.795 255.208C245.243 253.544 245.467 252.104 245.467 250.888C245.467 247.304 243.963 245.512 240.955 245.512Z"
          stroke="currentColor"
          stroke-width="6"
          mask="url(#path-1-outside-1)"
        />
        <path
          d="M279.214 243.784C272.814 243.784 269.614 250.76 269.614 264.712C269.614 270.408 270.318 275.016 271.726 278.536C273.198 281.992 276.014 283.72 280.174 283.72C282.99 283.72 285.39 282.984 287.374 281.512C289.422 279.976 290.798 277.832 291.502 275.08C292.59 275.592 293.358 276.2 293.806 276.904C294.318 277.544 294.574 278.44 294.574 279.592C294.574 281.896 293.806 283.944 292.27 285.736C290.798 287.464 288.878 288.776 286.51 289.672C284.142 290.632 281.71 291.112 279.214 291.112C272.174 291.112 266.958 288.84 263.566 284.296C260.174 279.688 258.478 273.512 258.478 265.768C258.478 257.512 260.302 250.856 263.95 245.8C267.662 240.68 273.23 238.12 280.654 238.12C285.006 238.12 288.59 239.016 291.406 240.808C294.222 242.6 295.63 245.352 295.63 249.064C295.63 251.24 294.958 252.904 293.614 254.056C292.27 255.208 290.446 255.784 288.142 255.784C287.438 255.784 286.702 255.688 285.934 255.496C285.23 255.304 284.654 255.048 284.206 254.728C284.718 253.192 284.974 251.752 284.974 250.408C284.974 245.992 283.054 243.784 279.214 243.784Z"
          stroke="currentColor"
          stroke-width="6"
          mask="url(#path-1-outside-1)"
        />
        <path
          d="M315.53 231.496C315.53 238.472 315.21 243.592 314.57 246.856C315.914 243.976 317.802 241.8 320.234 240.328C322.666 238.856 325.514 238.12 328.778 238.12C334.154 238.12 337.898 239.624 340.01 242.632C342.122 245.576 343.178 250.088 343.178 256.168V281.128C343.178 284.264 344.17 286.376 346.154 287.464C345.642 288.424 344.81 289.16 343.658 289.672C342.506 290.184 341.29 290.44 340.01 290.44C337.514 290.44 335.658 289.736 334.442 288.328C333.226 286.92 332.618 284.936 332.618 282.376V257.512C332.618 253.48 332.17 250.472 331.274 248.488C330.378 246.504 328.394 245.512 325.322 245.512C321.418 245.512 318.794 247.24 317.45 250.696C316.17 254.152 315.53 258.6 315.53 264.04V289H304.97V221.8L315.53 220.648V231.496Z"
          stroke="currentColor"
          stroke-width="6"
          mask="url(#path-1-outside-1)"
        />
        <path
          d="M361.056 219.88C363.168 219.88 364.864 220.392 366.144 221.416C367.488 222.44 368.16 223.912 368.16 225.832C368.16 227.816 367.456 229.352 366.048 230.44C364.704 231.464 362.944 231.976 360.768 231.976C358.656 231.976 356.96 231.464 355.68 230.44C354.4 229.416 353.76 227.944 353.76 226.024C353.76 224.04 354.432 222.536 355.776 221.512C357.184 220.424 358.944 219.88 361.056 219.88ZM366.048 281.128C366.048 284.264 367.04 286.376 369.024 287.464C368.512 288.424 367.68 289.16 366.528 289.672C365.376 290.184 364.128 290.44 362.784 290.44C360.352 290.44 358.528 289.736 357.312 288.328C356.096 286.92 355.488 284.936 355.488 282.376V240.04H366.048V281.128Z"
          stroke="currentColor"
          stroke-width="6"
          mask="url(#path-1-outside-1)"
        />
        <path
          d="M410.029 275.464C411.501 276.168 412.237 277.544 412.237 279.592C412.237 281.896 411.469 283.944 409.933 285.736C408.397 287.464 406.413 288.776 403.981 289.672C401.549 290.632 399.085 291.112 396.589 291.112C389.549 291.112 384.333 288.84 380.941 284.296C377.549 279.688 375.853 273.512 375.853 265.768C375.853 257.512 377.677 250.856 381.325 245.8C385.037 240.68 390.637 238.12 398.125 238.12C402.861 238.12 406.669 239.368 409.549 241.864C412.429 244.296 413.869 247.752 413.869 252.232C413.869 257.8 411.917 261.704 408.013 263.944C404.173 266.184 398.925 267.304 392.269 267.304C390.413 267.304 388.653 267.24 386.989 267.112C387.181 272.168 388.013 276.2 389.485 279.208C391.021 282.216 393.709 283.72 397.549 283.72C400.365 283.72 402.829 283.016 404.941 281.608C407.117 280.136 408.813 278.088 410.029 275.464ZM396.589 243.592C393.133 243.592 390.701 245.416 389.293 249.064C387.885 252.648 387.117 257.064 386.989 262.312C388.909 262.44 390.317 262.504 391.213 262.504C399.277 262.504 403.309 259.272 403.309 252.808C403.309 250.184 402.733 248.008 401.581 246.28C400.493 244.488 398.829 243.592 396.589 243.592Z"
          stroke="currentColor"
          stroke-width="6"
          mask="url(#path-1-outside-1)"
        />
        <path
          d="M439.646 263.896C439.646 265.592 439.086 267.112 437.966 268.456C436.846 269.768 435.39 270.904 433.598 271.864C435.806 273.144 437.582 274.52 438.926 275.992C440.27 277.464 440.942 279.192 440.942 281.176C440.942 284.184 439.918 286.424 437.87 287.896C435.822 289.336 433.102 290.056 429.71 290.056C426.414 290.056 423.726 289.32 421.646 287.848C419.566 286.344 418.526 284.136 418.526 281.224C418.526 279.4 419.15 277.72 420.398 276.184C421.678 274.648 423.278 273.432 425.198 272.536C423.438 271.256 422.062 269.992 421.07 268.744C420.078 267.464 419.582 266.008 419.582 264.376C419.582 261.784 420.542 259.8 422.462 258.424C424.382 257.048 426.814 256.36 429.758 256.36C432.67 256.36 435.038 256.984 436.862 258.232C438.718 259.48 439.646 261.368 439.646 263.896ZM425.87 263.896C425.87 265.08 426.302 266.152 427.166 267.112C428.03 268.04 429.262 269.032 430.862 270.088C431.822 269.352 432.558 268.456 433.07 267.4C433.614 266.312 433.886 265.144 433.886 263.896C433.886 262.648 433.502 261.64 432.734 260.872C431.966 260.104 430.974 259.72 429.758 259.72C428.542 259.72 427.582 260.104 426.878 260.872C426.206 261.608 425.87 262.616 425.87 263.896ZM434.654 281.704C434.654 280.744 434.35 279.848 433.742 279.016C433.134 278.184 432.398 277.448 431.534 276.808C430.67 276.168 429.438 275.352 427.838 274.36C426.846 275.224 426.062 276.264 425.486 277.48C424.91 278.696 424.622 279.944 424.622 281.224C424.622 282.92 425.054 284.264 425.918 285.256C426.814 286.216 428.078 286.696 429.71 286.696C431.31 286.696 432.526 286.264 433.358 285.4C434.222 284.536 434.654 283.304 434.654 281.704Z"
          stroke="currentColor"
          stroke-width="6"
          mask="url(#path-1-outside-1)"
        />
        <path
          d="M467.439 257.32L467.775 260.824L454.527 290.056L448.191 289L462.255 261.832L444.447 261.88L444.783 257.32H467.439Z"
          stroke="currentColor"
          stroke-width="6"
          mask="url(#path-1-outside-1)"
        />
        <path
          d="M484.048 256.36C485.776 256.36 487.311 256.728 488.655 257.464C490.031 258.2 490.72 259.256 490.72 260.632C490.72 261.08 490.608 261.464 490.384 261.784C490.192 262.104 489.887 262.408 489.471 262.696C488.191 261.384 486.511 260.728 484.431 260.728C481.807 260.728 479.839 261.704 478.527 263.656C477.247 265.576 476.543 268.12 476.415 271.288C476.927 270.072 477.839 269.128 479.151 268.456C480.495 267.784 481.968 267.448 483.568 267.448C486.416 267.448 488.607 268.472 490.143 270.52C491.679 272.536 492.448 275.096 492.448 278.2C492.448 280.44 492 282.472 491.104 284.296C490.24 286.088 488.991 287.496 487.359 288.52C485.727 289.544 483.823 290.056 481.647 290.056C474.191 290.056 470.464 284.792 470.464 274.264C470.464 271.128 470.976 268.2 472 265.48C473.024 262.728 474.56 260.52 476.608 258.856C478.656 257.192 481.136 256.36 484.048 256.36ZM481.647 271C480.079 271 478.911 271.72 478.143 273.16C477.407 274.568 476.976 276.36 476.848 278.536C476.944 280.776 477.359 282.696 478.095 284.296C478.831 285.896 480.015 286.696 481.647 286.696C483.311 286.696 484.511 285.88 485.247 284.248C486.015 282.616 486.4 280.712 486.4 278.536C486.4 276.616 485.999 274.888 485.199 273.352C484.399 271.784 483.215 271 481.647 271Z"
          stroke="currentColor"
          stroke-width="6"
          mask="url(#path-1-outside-1)"
        />
  </svg>
);

export default IconLoader;
