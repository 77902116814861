/* eslint-disable no-unused-vars */
/** @jsx jsx */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import sr from '@utils/sr';
import { srConfig } from '@config';
import { FormattedIcon } from '@components/icons';
import styled from 'styled-components';
import { theme, mixins, media, Section, Heading } from '@styles';
const { colors, fontSizes, fonts } = theme;
import { jsx } from 'theme-ui';
import SVG from '@components/svg';
import { UpDown, UpDownWide } from '@styles/animations';

const StyledContainer = styled(Section)`
  ${mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
`;
const StyledContent = styled.div`
  position: relative;
  grid-column: 1 / 7;
  grid-row: 1 / -1;
  ${media.thone`
    grid-column: 1 / -1;
    padding: 40px 40px 30px;
    z-index: 5;
  `};
  ${media.phablet`padding: 30px 25px 20px;`};
`;
const StyledLabel = styled.h4`
  font-size: ${fontSizes.smish};
  font-weight: normal;
  color: ${colors.yeYellow};
  font-family: ${fonts.SFMono};
  margin-top: 10px;
  padding-top: 0;
`;
const StyledProjectName = styled.h5`
  font-size: 28px;
  margin: 0 0 20px;
  color: ${colors.lightestSlate};
  ${media.tablet`font-size: 24px;`};
  ${media.thone`color: ${colors.white};`};
  a {
    ${media.tablet`display: block;`};
  }
`;
const StyledDescription = styled.div`
  ${mixins.boxShadow};
  position: relative;
  z-index: 2;
  padding: 25px;
  background-color: ${colors.lightNavy};
  color: ${colors.lightSlate};
  font-size: ${fontSizes.lg};
  border-radius: ${theme.borderRadius};
  ${media.thone`
    background-color: transparent;
    padding: 20px 0;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  `};
  p {
    margin: 0;
  }
  a {
    ${mixins.inlineLink};
  }
`;
const StyledTechList = styled.ul`
  position: relative;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 25px 0 10px;
  list-style: none;

  li {
    font-family: ${fonts.SFMono};
    font-size: ${fontSizes.smish};
    color: ${colors.slate};
    margin-right: ${theme.margin};
    margin-bottom: 7px;
    white-space: nowrap;
    &:last-of-type {
      margin-right: 0;
    }
    ${media.thone`
      color: ${colors.lightestSlate};
      margin-right: 10px;
    `};
  }
`;
const StyledLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 10px;
  margin-left: -10px;
  color: ${colors.lightestSlate};
  a {
    padding: 10px;
    svg {
      width: 22px;
      height: 22px;
    }
  }
`;
const StyledFeaturedImg = styled(Img)`
  width: 100%;
  max-width: 100%;
  vertical-align: middle;
  border-radius: ${theme.borderRadius};
  position: relative;
  mix-blend-mode: multiply;
  filter: grayscale(100%) contrast(1) brightness(90%);
  ${media.tablet`
    object-fit: cover;
    width: auto;
    height: 100%;
    filter: grayscale(100%) contrast(1) brightness(80%);
  `};
`;
const StyledImgContainer = styled.a`
  ${mixins.boxShadow};
  grid-column: 6 / -1;
  grid-row: 1 / -1;
  position: relative;
  z-index: 1;
  background-color: ${colors.yeYellow};
  border-radius: ${theme.radius + 1}px;
  transition: ${theme.transition};
  ${media.tablet`height: 100%;`};
  ${media.thone`
    grid-column: 1 / -1;
    opacity: 0.25;
  `};
  &:hover,
  &:focus {
    background: transparent;
    &:before,
    ${StyledFeaturedImg} {
      background: transparent;
      filter: none;
    }
  }
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    transition: ${theme.transition};
    background-color: ${colors.navy};
    mix-blend-mode: screen;
  }
`;
const StyledProject = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  margin-bottom: 100px;
  ${media.thone`
    margin-bottom: 70px;
  `};
  &:last-of-type {
    margin-bottom: 0;
  }
  &:nth-of-type(odd) {
    ${StyledContent} {
      grid-column: 7 / -1;
      text-align: right;
      ${media.thone`
        grid-column: 1 / -1;
        padding: 40px 40px 30px;
      `};
      ${media.phablet`padding: 30px 25px 20px;`};
    }
    ${StyledTechList} {
      justify-content: flex-end;
      li {
        margin-left: ${theme.margin};
        margin-right: 0;
      }
    }
    ${StyledLinkWrapper} {
      justify-content: flex-end;
      margin-left: 0;
      margin-right: -10px;
    }
    ${StyledImgContainer} {
      grid-column: 1 / 8;
      ${media.tablet`height: 100%;`};
      ${media.thone`
        grid-column: 1 / -1;
        opacity: 0.25;
      `};
    }
  }
`;

const Featured = ({ data }) => {
  const featuredProjects = data.filter(({ node }) => node);

  const revealTitle = useRef(null);
  const revealProjects = useRef([]);
  useEffect(() => {
    sr.reveal(revealTitle.current, srConfig());
    revealProjects.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, []);
  const square = document.getElementsByClassName('square');

  for (let i = 0; i < square.length; i++) {
    square[i].addEventListener('mouseenter', function() {
      this.classList.add('rubberBand');
      this.addEventListener(
        'animationend',
        function() {
          this.classList.remove('rubberBand');
        },
        false,
      );
    });
  }
  return (
    <div>
      <SVG icon="circle" hiddenMobile width={124} color="#805ad5" left="10%" top="260%" />
      <SVG icon="circle" width={32} color="icon_darkest" left="70%" top="260%" />
      <UpDown>
        <SVG icon="box" width={6} color="icon_brightest" left="85%" top="275%" />
        <SVG icon="upDown" width={8} color="icon_teal" left="70%" top="320%" />
        <SVG icon="triangle" width={8} stroke color="icon_orange" left="25%" top="305%" />
        <SVG icon="circle" hiddenMobile width={24} color="icon_brightest" left="17%" top="360%" />
      </UpDown>
      <UpDownWide>
        <SVG icon="arrowUp" hiddenMobile width={116} color="#48BB78" left="20%" top="280%" />
        <SVG icon="triangle" hiddenMobile width={42} stroke color="#FFF" left="90%" top="330%" />
        <SVG icon="circle" width={16} color="#FFF" left="80%" top="390%" />
        <SVG
          icon="triangle"
          hiddenMobile
          width={146}
          stroke
          color="#805ad5"
          left="15%"
          top="375%"
        />
        <SVG icon="circle" width={6} color="icon_brightest" left="75%" top="10%" />
        <SVG icon="upDown" hiddenMobile width={8} color="icon_green" left="45%" top="310%" />
        <SVG icon="upDown" hiddenMobile width={118} color="#3182CE" left="15%" top="310%" />
      </UpDownWide>
      <UpDownWide>
        <SVG icon="circle" hiddenMobile width={6} color="icon_brightest" left="4%" top="320%" />
        <SVG icon="circle" width={12} color="icon_pink" left="80%" top="360%" />
        <SVG icon="box" width={6} color="icon_orange" left="10%" top="310%" />
        <SVG icon="box" width={12} color="icon_yellow" left="29%" top="326%" />
        <SVG icon="hexa" width={16} stroke color="icon_red" left="75%" top="330%" />
        <SVG icon="hexa" width={8} stroke color="icon_yellow" left="80%" top="270%" />
      </UpDownWide>
      <StyledContainer id="projects">
        <div className="line square" id="square">
          <Heading ref={revealTitle}>
            <div className="square">My Projects</div>
          </Heading>
        </div>
        <div>
          {featuredProjects &&
            featuredProjects.map(({ node }, i) => {
              const { frontmatter, html } = node;
              const { external, title, tech, github, cover } = frontmatter;

              return (
                <StyledProject key={i} ref={el => (revealProjects.current[i] = el)}>
                  <StyledContent>
                    <StyledLabel>Featured Project</StyledLabel>
                    <StyledProjectName>
                      {external ? (
                        <a
                          href={external}
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                          aria-label="External Link">
                          {title}
                        </a>
                      ) : (
                        title
                      )}
                    </StyledProjectName>
                    <StyledDescription dangerouslySetInnerHTML={{ __html: html }} />
                    {tech && (
                      <StyledTechList>
                        {tech.map((tech, i) => (
                          <li key={i}>{tech}</li>
                        ))}
                      </StyledTechList>
                    )}
                    <StyledLinkWrapper>
                      {github && (
                        <a
                          href={github}
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                          aria-label="GitHub Link">
                          <FormattedIcon name="GitHub" />
                        </a>
                      )}
                      {external && (
                        <a
                          href={external}
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                          aria-label="External Link">
                          <FormattedIcon name="External" />
                        </a>
                      )}
                    </StyledLinkWrapper>
                  </StyledContent>

                  <StyledImgContainer
                    href={external ? external : github ? github : '#'}
                    target="_blank"
                    rel="nofollow noopener noreferrer">
                    <StyledFeaturedImg fluid={cover.childImageSharp.fluid} alt={title} />
                  </StyledImgContainer>
                </StyledProject>
              );
            })}
        </div>
      </StyledContainer>
    </div>
  );
};

Featured.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Featured;
