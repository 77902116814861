/* eslint-disable no-unused-vars */
/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { email } from '@config';
import styled from 'styled-components';
import { theme, mixins, media, Section } from '@styles';
import { jsx } from 'theme-ui';
import SVG from '@components/svg';
import { UpDown, UpDownWide } from '@styles/animations';
// @ts-ignore
const { colors, fontSizes, fonts, navDelay, loaderDelay } = theme;

const StyledContainer = styled(Section)`
  ${mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  ${media.tablet`padding-top: 150px;`};
  div {
    width: 100%;
  }
`;
const StyledOverline = styled.h1`
  color: ${colors.yeYellow};
  margin: 0 0 20px 3px;
  font-size: ${fontSizes.md};
  font-family: ${fonts.SFMono};
  font-weight: normal;
  ${media.desktop`font-size: ${fontSizes.sm};`};
  ${media.tablet`font-size: ${fontSizes.smish};`};
`;
const StyledTitle = styled.h2`
  font-size: 80px;
  line-height: 1.1;
  margin: 0;

  ${media.desktop`font-size: 70px;`};
  ${media.tablet`font-size: 60px;`};
  ${media.phablet`font-size: 50px;`};
  ${media.phone`font-size: 40px;`};
`;
const StyledSubtitle = styled.h3`
  font-size: 80px;
  line-height: 1.1;
  color: ${colors.grayishYellow};
  ${media.desktop`font-size: 70px;`};
  ${media.tablet`font-size: 60px;`};
  ${media.phablet`font-size: 50px;`};
  ${media.phone`font-size: 40px;`};
`;
const StyledDescription = styled.div`
  margin-top: 0px;
  width: 100%;
  max-width: 1000px;
  color: ${colors.slate};
  a {
    ${mixins.inlineLink};
  }
  p {
    font-size: 1.875rem;
  }
`;
const StyledEmailLink = styled.a`
  ${mixins.bigButton};
  margin-top: 50px;
`;

const Hero = ({ data }) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const { frontmatter, html } = data[0].node;

  const one = () => (
    <StyledOverline style={{ transitionDelay: '100ms' }}>{frontmatter.title}</StyledOverline>
  );
  const two = () => (
    <StyledTitle style={{ transitionDelay: '200ms' }}>{frontmatter.name}.</StyledTitle>
  );
  const three = () => (
    <StyledSubtitle style={{ transitionDelay: '300ms' }}>{frontmatter.subtitle}</StyledSubtitle>
  );
  const four = () => (
    <StyledDescription
      style={{ transitionDelay: '400ms' }}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
  const five = () => (
    <div style={{ transitionDelay: '500ms' }}>
      <StyledEmailLink href={`mailto:${email}`}>{frontmatter.buttonText}</StyledEmailLink>
    </div>
  );

  const items = [one, two, three, four, five];

  return (
    <section>
      <span hiddenMobile className="tags top-tags">
        {' '}
        &nbsp;&nbsp;&nbsp;&lt;body&gt;
      </span>
      <UpDown>
        <SVG icon="triangle" hiddenMobile width={228} stroke color="#ed8936" left="10%" top="20%" />
        <SVG icon="hexa" hiddenMobile width={228} stroke color="#e53e3e" left="60%" top="70%" />
        <SVG icon="box" hiddenMobile width={56} color="icon_darker" left="60%" top="15%" />
      </UpDown>
      <UpDownWide>
        <SVG icon="arrowUp" hiddenMobile width={86} color="#3182ce" left="80%" top="10%" />
        <SVG icon="triangle" hiddenMobile width={84} stroke color="#fff" left="90%" top="50%" />
        <SVG icon="circle" hiddenMobile width={66} color="icon_darker" left="70%" top="90%" />
        <SVG
          icon="triangle"
          hiddenMobile
          width={86}
          stroke
          color="icon_darkest"
          left="30%"
          top="65%"
        />
        <SVG icon="cross" hiddenMobile width={66} stroke color="#ed64a6" left="28%" top="15%" />
        <SVG icon="circle" width={16} color="icon_darkest" left="75%" top="10%" />
        <SVG icon="upDown" hiddenMobile width={18} color="icon_darkest" left="45%" top="10%" />
      </UpDownWide>
      <UpDownWide>
        <SVG icon="circle" hiddenMobile width={8} color="icon_darker" left="5%" top="70%" />
        <SVG icon="circle" width={6} color="icon_darkest" left="4%" top="20%" />
        <SVG icon="circle" width={12} color="icon_darkest" left="50%" top="60%" />
        <SVG icon="upDown" hiddenMobile width={8} color="#FFF" left="95%" top="90%" />
        <SVG icon="upDown" hiddenMobile width={14} color="#FFF" left="40%" top="80%" />
        <SVG icon="triangle" width={8} stroke color="icon_darker" left="25%" top="5%" />
        <SVG icon="circle" hiddenMobile width={154} color="#48bb78" left="98%" top="15%" />
        <SVG icon="box" hiddenMobile width={228} color="#805ad5" left="5%" top="90%" />
        <SVG icon="box" width={16} color="icon_darkest" left="10%" top="10%" />
        <SVG icon="box" width={12} color="icon_darkest" left="40%" top="30%" />
        <SVG icon="hexa" width={16} stroke color="icon_darker" left="10%" top="50%" />
        <SVG icon="hexa" width={18} stroke color="icon_darker" left="80%" top="70%" />
      </UpDownWide>
      <StyledContainer>
        <TransitionGroup component={null}>
          {isMounted &&
            items.map((item, i) => (
              <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
                {item}
              </CSSTransition>
            ))}
        </TransitionGroup>
      </StyledContainer>
      <span className="tags bottom-tags">
        {' '}
        &nbsp;&nbsp;&nbsp;&lt;/body&gt;
        <br /> &lt;/html&gt;
      </span>
    </section>
  );
};

Hero.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Hero;
