module.exports = {
  siteTitle: 'Dillon Parchment | Web Developer| Software Engineer',
  siteTitleAlt: `Dillon Parchment`,
  siteDescription:
    ' Dillon Parchment is a software engineer based in Kingston, Jamaica who specializes in building  exceptional websites, applications, and everything in between.',
  siteKeywords:
    'Dillon Parchment, Dillon, Parchment, Parchie876, Parchie876IX, software engineer, full stack develope, web developer, javascript, UTECH, Jamaica, utech',
  siteHeadline: `Dillon Parchment`,
  siteUrl: 'https://dillonparchment.com',
  siteLanguage: 'en_US',
  basePath: `/`,
  name: 'Dillon Parchment',
  location: 'Kingston, Jamaica',
  email: 'parchie876ix@gmail.com',
  github: 'https://github.com/Parchie876',
  twitterHandle: '@parchie876ix',
  socialMedia: [
    {
      name: 'GitHub',
      url: 'https://github.com/Parchie876',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/dillonparchment',
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/parchie876',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/parchie876ix',
    },
  ],

  navLinks: [
    {
      name: 'About',
      url: '/#about',
    },
    {
      name: 'Experience',
      url: '/#jobs',
    },
    {
      name: 'Work',
      url: '/#projects',
    },
    {
      name: 'Contact',
      url: '/#contact',
    },
    {
      name: 'Blog',
      url: '/blog',
    },
  ],

  navHeight: 100,

  colors: {
    green: '#64ffda',
    navy: '#0a192f',
    darkNavy: '#020c1b',
    alizarinRed: '#E1243B',
    deepBlack: '#212121',
    regentGray: '#8A9499',
    yeYellow: '#feda6a',
    silverFox: '#d4d4dc',
    deepMatteGrey: '#393f4d',
    darkSlate: '#1d1e22',
    lightNavy: '#112240',
  },

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
