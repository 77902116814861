import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import sr from '@utils/sr';
import { srConfig, email } from '@config';
import styled from 'styled-components';
import { theme, mixins, media, Section, Heading } from '@styles';
import SVG from '@components/svg';
import { UpDown, UpDownWide } from '@styles/animations';
const { colors, fontSizes, fonts } = theme;

const StyledContainer = styled(Section)`
  text-align: center;
  max-width: 600px;
  margin: 0 auto 100px;
  a {
    ${mixins.inlineLink};
  }
`;
const StyledHeading = styled(Heading)`
  display: block;
  font-size: ${fontSizes.md};
  font-family: ${fonts.SFMono};
  font-weight: normal;
  margin-bottom: 20px;
  justify-content: center;
  ${media.desktop`font-size: ${fontSizes.sm};`};
  &:before {
    bottom: 0;
    font-size: ${fontSizes.sm};
    ${media.desktop`font-size: ${fontSizes.smish};`};
  }
  &:after {
    display: none;
  }
`;
const StyledTitle = styled.h4`
  margin: 0 0 20px;
  font-size: 60px;
  ${media.desktop`font-size: 50px;`};
  ${media.tablet`font-size: 40px;`};
`;
const StyledEmailLink = styled.a`
  ${mixins.bigButton};
  margin-top: 50px;
`;

const Contact = ({ data }) => {
  const { frontmatter, html } = data[0].node;
  const { title, buttonText } = frontmatter;
  const revealContainer = useRef(null);
  useEffect(() => sr.reveal(revealContainer.current, srConfig()), []);

 var square = document.getElementsByClassName('square');

for(var i = 0; i<square.length; i++){
  square[i].addEventListener('mouseenter', function(){
    this.classList.add("rubberBand");
    this.addEventListener("animationend", function(){
      this.classList.remove("rubberBand");
    }, false);
  })
}

  return (
    <div>
      <UpDownWide>
        <SVG icon="box" hiddenMobile width={116} color="#3182CE" left="85%" top="415%" />
        <SVG icon="upDown" hiddenMobile width={118} color="#805AD5" left="75%" top="437%" />
        <SVG icon="triangle" hiddenMobile width={148} stroke color="#E53E3E" left="10%" top="409%" />
        <SVG icon="circle" hiddenMobile width={144} color="#ED64A6" left="17%" top="430%" />
      </UpDownWide>
    <StyledContainer id="contact" ref={revealContainer}>
    <UpDown>
        <SVG icon="box" width={16} color="icon_brightest" left="85%" top="75%" />
        <SVG icon="upDown" width={18} color="icon_teal" left="70%" top="20%" />
        <SVG icon="triangle" width={18} stroke color="icon_orange" left="25%" top="5%" />
        <SVG icon="circle"  width={34} color="icon_brightest" left="17%" top="60%" />
      </UpDown>
      <UpDownWide>
        <SVG icon="arrowUp"  width={26} color="icon_green" left="20%" top="90%" />
        <SVG icon="triangle" width={22} stroke color="icon_brightest" left="90%" top="30%" />
        <SVG icon="circle" width={26} color="icon_yellow" left="70%" top="90%" />
        <SVG icon="triangle"  width={26} stroke color="icon_teal" left="18%" top="75%" />
        <SVG icon="circle" width={16} color="icon_brightest" left="75%" top="10%" />
        <SVG icon="upDown"  width={18} color="icon_green" left="45%" top="10%" />

      <SVG icon="circle"  width={16} color="icon_brightest" left="4%" top="20%" />
      <SVG icon="circle" width={22} color="icon_pink" left="80%" top="60%" />
      <SVG icon="box" width={16} color="icon_orange" left="10%" top="10%" />
      <SVG icon="box" width={22} color="icon_yellow" left="29%" top="26%" />
      <SVG icon="hexa" width={26} stroke color="icon_red" left="75%" top="30%" />
      <SVG icon="hexa" width={18} stroke color="icon_yellow" left="80%" top="70%" />
      </UpDownWide>     
      <div class="line square" id="square">
      <StyledHeading><div class="square">What&apos;s Next?</div></StyledHeading>
      </div>
      <StyledTitle>{title}</StyledTitle>

      <div dangerouslySetInnerHTML={{ __html: html }} />

      <StyledEmailLink href={`mailto:${email}`} target="_blank" rel="nofollow noopener noreferrer">
        {buttonText}
      </StyledEmailLink>
    </StyledContainer>
    </div>
  );
};

Contact.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Contact;
